<template>
  <div v-show="visible" class="preview_img_container">
    <div id="pic_view" class="pic_view">
      <img
        v-for="(it, idx) in carouselList"
        :key="idx"
        :src="`${$PicPrefix}${it['nanoId']}`"
        alt=""
        style="display: none"
      />
    </div>
    <svg
      aria-hidden="true"
      class="icon close_btn curp"
      @click="closePicView"
      @mouseleave="svgColor = false"
      @mousemove="svgColor = true"
    >
      <use v-if="!svgColor" xlink:href="#icon-guanbi_fanbai"></use>
      <use v-else xlink:href="#icon-guanbi_xuantingdianji"></use>
    </svg>
    <img alt="" class="prev option_btn" src="./img/prev.png" @click="prev" />
    <img alt="" class="next option_btn" src="./img/next.png" @click="next" />
    <img alt="" src="" />
    <div class="toolbar">
      <div class="page_set">
        <div class="current_name" :title="currentName">
          {{ currentName }}
        </div>
        <div class="page_size">
          {{ currentIdx + "/" + this.carouselList.length }}
        </div>
      </div>
      <div
        :style="language === 'en_US' ? 'width: 1.1rem' : ''"
        class="options_item fd_item"
        @click="imgChange('0')"
      >
        <div class="fd_img options_item_img"></div>
        <p class="c-white mt4">{{ $t("common.common.fd") }}</p>
      </div>
      <div
        :style="language === 'en_US' ? 'width: 1.1rem' : ''"
        class="options_item sx_item"
        @click="imgChange('1')"
      >
        <div class="sx_img options_item_img"></div>
        <p class="c-white mt4">{{ $t("common.common.sx") }}</p>
      </div>
      <div
        :style="language === 'en_US' ? 'width: 1.1rem' : ''"
        class="options_item sxfz_item"
        @click="imgChange('2')"
      >
        <div class="sxfz_img options_item_img"></div>
        <p class="c-white mt4">{{ $t("common.common.sxfz") }}</p>
      </div>
      <div
        :style="language === 'en_US' ? 'width: 1.1rem' : ''"
        class="options_item fz_item"
        @click="imgChange('3')"
      >
        <div class="fz_img options_item_img"></div>
        <p class="c-white mt4">{{ $t("common.common.zyfz") }}</p>
      </div>
      <div
        :style="language === 'en_US' ? 'width: 1.1rem' : ''"
        class="options_item zz_item"
        @click="imgChange('4')"
      >
        <div class="zz_img options_item_img"></div>
        <p class="c-white mt4">{{ $t("common.common.zz") }}</p>
      </div>
      <div
        :style="language === 'en_US' ? 'width: 1.1rem' : ''"
        class="options_item yz_item"
        @click="imgChange('5')"
      >
        <div class="yz_img options_item_img"></div>
        <p class="c-white mt4">{{ $t("common.common.yz") }}</p>
      </div>
      <div
        :style="language === 'en_US' ? 'width: 1.1rem' : ''"
        class="options_item xz_item"
        @click="imgChange('6')"
      >
        <div class="xz_img options_item_img"></div>
        <p class="c-white mt4">{{ $t("common.common.xz") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";
import { downImage } from "common/js/util";
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      visible: false, // 是否显示当前组件
      svgColor: false, // 关闭按钮颜色
      imgView: {}, // 图片node
      viewer: {}, // 组件node
      isHorizontal: false, // 水平向
      isVertical: false, // 垂直向
      currentName: "", // 当前图片名称
      currentIdx: 0 // 当前图片下标
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      language: state => state.language
    })
  },
  components: {
    Viewer
  },
  props: {
    carouselList: {
      // item 必须要有 name key选传
      type: Array,
      default: [],
      required: true
    },
    onlyRead: {
      required: false
    }
  },
  methods: {
    escEvent(e) {
      if (e.keyCode == 27 && this.visible) {
        this.closePicView();
      }
      if (e.keyCode == 39 && this.visible) {
        this.viewer.next();
      }
      if (e.keyCode == 37 && this.visible) {
        this.viewer.prev();
      }
    },
    onMouseWheel(e) {
      const dir = e.wheelDelta;
      $(this.imgView).css("transition", ".4s linear");
      if (dir > 0) {
        this.viewer.zoom(0.05, true);
      }
      if (dir < 0) {
        this.viewer.zoom(-0.05, true);
      }
    },
    imgChange(type) {
      const nowIdx = this.viewer["index"];
      let current = this.carouselList[nowIdx];
      const diff = Math.abs(this.viewer["imageData"].rotate) / 90;
      const isEven = diff % 2 == 0;
      switch (type) {
        case "0":
          $(this.imgView).css("transition", ".4s linear");
          this.viewer.transition = true;
          this.viewer.zoom(0.1, true);
          break;
        case "1":
          $(this.imgView).css("transition", ".4s linear");
          this.viewer.transition = true;
          this.viewer.zoom(-0.1, true);
          break;
        case "2":
          $(this.imgView).css("transition", ".4s linear");
          this.viewer.transition = true;
          if (!isEven) {
            this.isHorizontal = !this.isHorizontal;
            this.viewer.scaleX(this.isHorizontal ? -1 : 1);
          } else {
            this.isVertical = !this.isVertical;
            this.viewer.scaleY(this.isVertical ? -1 : 1);
          }
          break;
        case "3":
          $(this.imgView).css("transition", ".4s linear");
          this.viewer.transition = true;
          if (!isEven) {
            this.isVertical = !this.isVertical;
            this.viewer.scaleY(this.isVertical ? -1 : 1);
          } else {
            this.isHorizontal = !this.isHorizontal;
            this.viewer.scaleX(this.isHorizontal ? -1 : 1);
          }
          break;
        case "4":
          $(this.imgView).css("transition", ".4s linear");
          this.viewer.transition = true;
          this.viewer.rotate(-90);
          break;
        case "5":
          $(this.imgView).css("transition", ".4s linear");
          this.viewer.transition = true;
          this.viewer.rotate(90);
          break;
        case "6":
          let img = `${this.$PicPrefix}${current["nanoId"]}`;
          setTimeout(() => {
            downImage(img, current.name);
          }, 100);
          break;
      }
      let { rotate, scaleX, scaleY } = this.viewer["imageData"];
      if (Math.sign(rotate) == 0) {
        current.degree = 0;
      }
      if (Math.sign(rotate) !== 1 && rotate !== 0) {
        current.degree = 360 - Math.abs(rotate);
      } else {
        current.degree = rotate;
      }
      current.horizontal = this.isHorizontal;
      current.vertical = this.isVertical;
      this.$emit("imgChange", current, nowIdx);
    },
    prev() {
      $(this.imgView).css("transition", "none");
      this.viewer.prev();
    },
    next() {
      $(this.imgView).css("transition", "none");
      this.viewer.next();
    },
    closePicView() {
      this.visible = false;
      this.isHorizontal = false;
      this.isVertical = false;
      this.viewer.destroy();
    },
    show(name, key) {
      document.addEventListener("keyup", this.escEvent);
      // document.addEventListener("mousewheel", this.onMouseWheel);
      this.visible = true;
      const ViewerDom = document.getElementById("pic_view");
      const _this = this;
      this.viewer = new Viewer(ViewerDom, {
        inline: true,
        button: false,
        toolbar: false,
        title: false,
        navbar: false,
        transition: false,
        initialViewIndex: key,
        zoomOnWheel: true,
        className: "viewer_preview",
        viewed() {
          const viewerContainer = this.viewer.viewer;
          $(viewerContainer).css("background", "rgba(46, 47, 48, 0.9)");
          const current = _this.carouselList[_this.viewer.index];
          _this.currentIdx = _this.viewer.index + 1;
          _this.currentName = current["name"];
          _this.isHorizontal = current.horizontal || false;
          _this.isVertical = current.vertical || false;
          _this.viewer.rotate(current.degree || 0);
          _this.viewer.scaleX(current.horizontal ? -1 : 1);
          _this.viewer.scaleY(current.vertical ? -1 : 1);
          _this.imgView = this.viewer["image"];
          $(this.imgView).mouseenter(() => {
            this.dragImg();
          });
        },
        zoom() {
          $(this.imgView).css("transition", ".4s linear");
          this.viewer.transition = true;
        },
        move() {
          $(this.imgView).css("transition", ".4s linear");
          this.viewer.transition = true;
        }
      });
      this.viewer.show();
    }
  }
};
</script>

<style lang="scss">
.preview_img_container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 999999;
}

.option_btn {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  width: 64px;
  height: 64px;
  cursor: pointer;
}

.prev {
  left: 100px;
}

.next {
  right: 100px;
}

.close_btn {
  position: fixed;
  right: 40px;
  top: 40px;
  width: 24px;
  height: 24px;
}

.toolbar {
  position: fixed;
  width: 1020px;
  height: 88px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 50px;
  background-color: rgba($color: #38383a, $alpha: 0.8);
  backdrop-filter: blur(4px);
  border-radius: 12px;
  display: flex;
  padding: 0 24px;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  .page_set {
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .current_name {
      max-width: 170px;
      font-size: 24px;
      color: $main_theme_color;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 23px;
    }

    .page_size {
      text-align: right;
      font-size: 20px;
      color: #ffffff;
    }
  }

  .options_item {
    width: 80px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    cursor: pointer;
    text-align: center;

    .options_item_img {
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      /*transition: all 0.1s;*/
    }

    .fd_img {
      background-image: url("./img/fd.png");
    }

    .sx_img {
      background-image: url("./img/sx.png");
    }

    .sxfz_img {
      background-image: url("./img/sxfz.png");
    }

    .fz_img {
      background-image: url("./img/fz.png");
    }

    .zz_img {
      background-image: url("./img/zz.png");
    }

    .yz_img {
      background-image: url("./img/yz.png");
    }

    .xz_img {
      background-image: url("./img/xz.png");
    }
  }

  .fd_item:hover .fd_img {
    background-image: url("./img/fd_y.png");
  }

  .sx_item:hover .sx_img {
    background-image: url("./img/sx_y.png");
  }

  .sxfz_item:hover .sxfz_img {
    background-image: url("./img/sxfz_y.png");
  }

  .fz_item:hover .fz_img {
    background-image: url("./img/fz_y.png");
  }

  .zz_item:hover .zz_img {
    background-image: url("./img/zz_y.png");
  }

  .yz_item:hover .yz_img {
    background-image: url("./img/yz_y.png");
  }

  .xz_item:hover .xz_img {
    background-image: url("./img/xz_y.png");
  }
}

/deep/ .transition {
  transition: 0.4s linear;
}

/deep/ .viewer-backdrop {
  background: rgba(46, 47, 48, 0.9);
  // backdrop-filter: blur(4px);
}
</style>
